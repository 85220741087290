import React, { useEffect, useRef, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Facebook_icon from '../assets/loginsettings/facebook_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import Apple_icon from '../assets/loginsettings/apple_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import {
  ValidateMobileNumber,
  appleLogInHandlerForMobile,
  checkSuccessCode,
  errorHandlerForMobile,
  googleLogInHandlerForMobile,
  loginHandlerForMobile,
  validateEmail,
} from '../../utills/validator';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { setRegisterDetails } from '../../Reducer/commonData';
import ReCAPTCHA from 'react-google-recaptcha';
import { CSCSendEvent, clickAction, eventLocation, eventType } from '../../analytics';
import { Country_flag_json } from '../common/country-flag-json';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleLogin from 'react-apple-login';
import NewLoader from '../../utills/NewLoader';
import { isValidEmail, isValidNumber } from '../user/utills';

export function NewSignUp() {
  const queryParams = new URLSearchParams(window.location.search);
  const [showAppleLogin, setShowAppleLogin] = useState(true);
  const redirectUrl = queryParams.get('redirectUrl');
  const mobileView = queryParams.get('mobileView') || false;
  const apple_id_token = queryParams.get('apple_id_token');
  const clientId = queryParams.get('clientId');
  const purchaseType = queryParams.get('purchaseType');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newClientConfig.signup);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const clientDetails = useSelector((state) => state.clientDetails);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState({
    recaptchaVersion: '',
    recaptchaToken: '',
  });
  const checkinput = (value) => {
    if (toggleData?.loginMethods?.enableEmailLogin && validateEmail(value.toLowerCase())) {
      setBtnDisabled(false);
      return dispatch(
        setRegisterDetails({
          ...clientDetails,
          emailPhone: value,
          email: value.toLowerCase(),
          primary: 'email',
          loginType: 'EMAIL',
        }),
      );
    }
    if (toggleData?.loginMethods?.enableMobileLogin && ValidateMobileNumber(value)) {
      setBtnDisabled(false);
      return dispatch(
        setRegisterDetails({ ...clientDetails, emailPhone: value, phone: value, primary: 'phone', loginType: 'PHONE' }),
      );
    }
    dispatch(
      setRegisterDetails({ ...clientDetails, emailPhone: value, phone: '', email: '', primary: '', loginType: '' }),
    );
    setBtnDisabled(true);
  };
  const handleRegister = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/register`,
        data: {
          ...(clientDetails?.phone && { phone: clientDetails?.phone, countryCode: '+91' }),
          ...(clientDetails?.email && { email: clientDetails?.email }),
          clientGroupId: clientGroupId,
          primary: clientDetails?.primary,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          paywallId: commanUrlValue.paywallId,
          paywallType: commanUrlValue.paywallType,
        },
      });
      if (checkSuccessCode(response)) {
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/register', email: clientDetails?.email, phone: clientDetails?.phone },
        });
      }
    } catch (error) {
      return antdMessageError(error?.response?.data?.message);
    }
  };

  const handleTokenv2 = (token) => {
    setRecaptcha({ ...recaptcha, recaptchaToken: token });
  };
  const getUserLoginDetails = async () => {
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_SIGNUP,
      clickAction: clientDetails.email ? 'EMAIL' : 'PHONE',
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);

    let token = '';
    if (toggleData?.captcha?.enabled && toggleData?.captcha?.onSignup && toggleData?.captcha?.captchaVersion === 3) {
      token = await recaptchaRef.current.executeAsync();
    }
    try {
      const getDetailsResponse = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/sso/check-account-status`,
        params: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          email: isValidEmail(clientDetails?.email),
          phone: isValidNumber(clientDetails?.phone),
          ...(token && {
            token: token,
          }),
          ...(recaptcha?.recaptchaToken && {
            token: recaptcha?.recaptchaToken,
          }),
          type: 'onSignup',
        },
      });
      if (checkSuccessCode(getDetailsResponse)) {
        if (getDetailsResponse?.data?.status) {
          if (getDetailsResponse?.data?.validated) {
            antdMessageWarning('Looks like you already have an account! Please log in.');
            navigate(`/login?${queryParams.toString()}`);
          } else {
            antdMessageError('Recaptcha token is invalid');
          }
        } else {
          if (
            toggleData?.secondaryFields?.enabled ||
            (toggleData?.accessMethods?.otp && toggleData?.accessMethods?.password)
          ) {
            navigate(`/personalinfo?${queryParams.toString()}`, { state: '/personalinfo' });
          } else if (toggleData?.accessMethods?.otp) {
            handleRegister();
          } else {
            navigate(`/setPassword?${queryParams.toString()}`, { state: '/register' });
          }
        }
      }
    } catch (error) {
      return antdMessageError(
        'We encountered an issue while checking your registration status. Please try again later.',
      );
    }
  };
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isDesktop = !isAndroid && !isIOS;

    if (isAndroid && mobileView) {
      setShowAppleLogin(false); // Hide for Android WebView
    } else {
      setShowAppleLogin(true); // Show for other platforms
    }
  }, []);
  
  useEffect(() => {
    if (validateEmail(clientDetails.email)) {
      return setBtnDisabled(false);
    }
    if (ValidateMobileNumber(clientDetails.phone)) {
      return setBtnDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (toggleData?.captcha?.captchaVersion === 2) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'normal' });
    } else if (toggleData?.captcha?.captchaVersion === 3) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'invisible' });
    }
  }, [toggleData?.captcha]);

  useEffect(() => {
    const ssoSignupViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_SIGNUP,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoSignupViewObject);
  }, [commanUrlValue]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/google-login`,
        data: {
          token: tokenResponse.access_token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
        },
      });
      localStorage.setItem('code', googleLoginResponse.data.accessToken);
      const userCode = googleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
              } else {
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                window.location.href = fetchRedirectUrl.href;
              }
            }
          } else {
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          localStorage.removeItem('code');
          console.log(err, 'error on generating auth code');
        }
      }
    },
    flow: 'implicit',
  });
  const callGoogleLogin = () => {
    if (mobileView) {
      googleLogInHandlerForMobile(JSON.stringify({ message: 'GOOGLE_LOGIN_CLICK' }));
    } else {
      handleGoogleLogin();
    }
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_SIGNUP,
      clickAction: clickAction.GOOGLE,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
  };

  const handleAppleLogin = async (token) => {
    setLoading(true);
    try {
      const appleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/apple-login`,
        data: {
          token: token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
        },
      });
      localStorage.setItem('code', appleLoginResponse.data.accessToken);
      const userCode = appleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              if (queryParams.get('origin') === 'subsPage') {
                navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
                setLoading(false);
              } else {
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                window.location.href = fetchRedirectUrl.href;
                setLoading(false);
              }
            }
          } else {
            setLoading(false);
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          localStorage.removeItem('code');
          console.log(err, 'error on generating auth code');
        }
      }
    } catch (error) {
      if (!checkSuccessCode(error)) {
        setLoading(false);
        antdMessageError(error?.response?.data?.message);
        return errorHandlerForMobile(JSON.stringify(error?.response?.data));
      }
    }
  };

  useEffect(() => {
    if (apple_id_token) {
      handleAppleLogin(apple_id_token);
    }
  }, [apple_id_token]);

  const callFacebookLogin = () => {
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_SIGNUP,
      clickAction: clickAction.FACEBOOK,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        getUserLoginDetails();
      }
    }
  };
  if (loading) {
    return <NewLoader />;
  }
  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['p-4']}></div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['heading-container']}>
            <div>
              <p className={loginStyle['heading-text']} style={data?.signUpFormTitle}>
                {data?.signUpFormTitle?.text || 'Create new account'}
              </p>
              <div className={loginStyle['sub-text']} style={data?.logInLinkText}>
                {data?.logInLinkText?.text || 'Already have an account '}&nbsp;&nbsp;
                <span className={loginStyle['link']} onClick={() => navigate(`/login?${queryParams.toString()}`)}>
                  Log In
                </span>
              </div>
            </div>
          </div>
          <div className={loginStyle['form-container']}>
            {toggleData?.loginMethods?.enableEmailLogin && toggleData?.loginMethods?.enableMobileLogin ? (
              <div className={loginStyle['input-container-common']}>
                <img src={Profile_icon} alt="Profile_icon" />
                <Input
                  placeholder={data?.signUpInputText?.text || 'Email / Mobile '}
                  style={data?.signUpInputText}
                  onChange={(e) => checkinput(e.target.value)}
                  value={clientDetails?.emailPhone}
                  onKeyDown={keyDownHandler}
                />
              </div>
            ) : (
              <>
                {toggleData?.loginMethods?.enableEmailLogin && (
                  <div className={loginStyle['input-container-common']}>
                    <img src={Email_icon} alt="Email_icon" />
                    <Input
                      type="email"
                      required
                      placeholder={data?.signUpInputText?.text || 'Email'}
                      style={data?.signUpInputText}
                      onChange={(e) => checkinput(e.target.value)}
                      value={clientDetails?.emailPhone}
                      onKeyDown={keyDownHandler}
                    />
                  </div>
                )}
                {toggleData?.loginMethods?.enableMobileLogin && (
                  <div className={loginStyle['input-container']}>
                    <img src={Mobile_icon} alt="Mobile_icon" />
                    <div className={loginStyle['country-code']}>+91</div>
                    <div className={loginStyle['vertical-line']}></div>
                    <Input
                      placeholder={data?.signUpInputText?.text || '9999999999'}
                      style={data?.signUpInputText}
                      onChange={(e) => {
                        const reg = /^-?\d+(\.\d*)?$/;
                        if (reg.test(e.target.value) || e.target.value === '') {
                          checkinput(e.target.value);
                        }
                      }}
                      value={clientDetails?.emailPhone}
                      onKeyDown={keyDownHandler}
                    />
                  </div>
                )}
              </>
            )}
            {toggleData?.captcha?.enabled && toggleData?.captcha?.onSignup && recaptcha?.recaptchaVersion && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '15px',
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size={recaptcha?.recaptchaVersion}
                  sitekey={toggleData?.captcha?.captchaKey}
                  onChange={handleTokenv2}
                />
              </div>
            )}
          </div>
          <button
            className={loginStyle['btn-style']}
            disabled={btnDisabled}
            style={{
              ...data?.buttonText,
              opacity: btnDisabled ? '0.5' : '1',
              cursor: btnDisabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => getUserLoginDetails()}
          >
            {data?.buttonText?.text || 'Next'}
          </button>

          {(toggleData?.loginMethods?.enableFacebookLogin ||
            toggleData?.loginMethods?.enableGoogleLogin ||
            toggleData?.loginMethods?.enableAppleLogin) && (
            <div className={loginStyle['continueWithContainer']}>
              <hr className={loginStyle['horizontalLine']} />
              <div className={loginStyle['continueWith']} style={data?.dividerText}>
                {data?.dividerText?.text || 'Or Sign up using'}
              </div>
              <hr className={loginStyle['horizontalLine']} />
            </div>
          )}
          {toggleData?.loginMethods?.enableGoogleLogin && (
            <button className={loginStyle['google-btn']} onClick={() => callGoogleLogin()}>
              <img
                src="https://stage0conscent.blob.core.windows.net/stage-conscent-public/google-logo.svg"
                alt="Google logo"
              />
              <div style={data?.googleSsoText}>{data?.googleSsoText?.text || 'Google'}</div>
            </button>
          )}
          {/* {toggleData?.loginMethods?.enableFacebookLogin && (
            <FacebookLogin
              appId="367167408962065"
              onSuccess={(res) => console.log(res, 'res_Success')}
              onFail={(res) => console.log(res, 'res_Failour')}
              render={({ onClick }) => (
                <button className={loginStyle['facebook-btn']} onClick={onClick}>
                  <img src={Facebook_icon} alt="Facebook_icon" />
                  <div style={data?.facebookSsoText}>{data?.facebookSsoText?.text || 'Facebook'}</div>
                </button>
              )}
            />
          )} */}
          {toggleData?.loginMethods?.enableAppleLogin && showAppleLogin && (
            <AppleLogin
              clientId={toggleData?.appleServiceId} // Your Service ID
              redirectURI={window.location.origin} // Your Redirect URI
              usePopup={true} // or false, depending on your implementation
              scope="name email"
              render={(props) => (
                <button
                  className={loginStyle['google-btn']}
                  style={{ marginTop: '25px' }}
                  onClick={() => {
                    if (mobileView) {
                      appleLogInHandlerForMobile(JSON.stringify({ message: 'APPLE_LOGIN_CLICK' }));
                    } else {
                      props.onClick();
                    }
                  }}
                >
                  <img src={Apple_icon} alt="Apple_icon" />
                  <div style={data?.appleSsoText}>{data?.appleSsoText?.text || 'Apple'}</div>
                </button>
              )}
              callback={(token) => {
                if (token?.authorization?.id_token) {
                  handleAppleLogin(token?.authorization?.id_token);
                } else {
                  antdMessageError(token?.error?.error);
                }
              }}
            />
          )}
        </div>
        <div
          onClick={() => {
            callGoogleLogin();
          }}
          id="google"
        ></div>
      </div>
    </div>
  );
}
